import React from 'react'
import Layout from '../components/layout/Layout'
import NotFound from '../components/notFound/NotFound'
import Helmet from 'react-helmet'

const Page = ({pageContext}) => (
    <Layout
        locale={pageContext.locale}
        lang={pageContext.lang}
        alternatePath={pageContext.alternatePath}
        alternateLocale={pageContext.alternateLocale}>
        <Helmet>
            <title>Aquilas — 404</title>
        </Helmet>
        <NotFound/>
    </Layout>
)


export default Page
