import React, {PureComponent} from 'react'
import styles from './NotFound.module.scss'
import Headline from '../headline/Headline'
import Draggable from '../draggable/Draggable'
import Button from '../button/Button'
import Container from '../container/Container'
import Text from '../text/Text'

class NotFound extends PureComponent {

    constructor() {
        super()
        this.state = {
            gifUrl: null
        }
    }

    componentDidMount() {
        const giphy = {
            baseURL: 'https://api.giphy.com/v1/gifs/',
            key: 'dc6zaTOxFJmzC',
            tag: 'panic',
            type: 'random',
            rating: 'pg-13'
        }

        const url = encodeURI(`${giphy.baseURL}${giphy.type}?api_key=${giphy.key}&tag=${giphy.tag}&rating=${giphy.rating}`)

        fetch(url)
            .then(response => response.json())
            .then(json => this.setState({gifUrl: json.data.image_original_url}))
    }

    render() {
        const {gifUrl} = this.state
        return (
            <div className={styles.Main}>
                <Headline headline={{text: '404 Error'}}/>
                <Container>
                    <Text text={{html: '<h3>Page could not be found</h3>'}}/>
                </Container>
                <Draggable
                    left={Math.random()}
                    top={Math.random()}>
                    <Button to='/'>Back to home</Button>
                </Draggable>
                {gifUrl &&
                <Draggable left={Math.random() * 0.5}
                           top={Math.random() * 0.5}>
                    <img src={gifUrl} alt="Panic!"/>
                </Draggable>
                }
            </div>
        )
    }
}

NotFound.propTypes = {}

export default NotFound
